




































































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPreLab2Q2',
  components: {AiLoadingOverlay, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part2: '',
        language: 'en',
      },
      enthalpyItems: [
        {
          en: 'Enthalpy of dissolution of the ionic compound $\\ce{X+Y-}$',
          fr: 'L’enthalpie de mise en solution du sel $\\ce{X+Y-}$',
        },
        {
          en: 'Lattice energy of the ionic compound $\\ce{X+Y-}$',
          fr: 'L’énergie réticulaire du sel $\\ce{X+Y-}$',
        },
        {
          en: 'Enthalpy of hydration of the gaseous ion $\\ce{Z+ (g)}$',
          fr: 'L’énergie d’hydratation de l’ion gazeux $\\ce{Z+ (g)}$',
        },
      ],
      attachments: [] as File[],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
